import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {
    state: {
        units_msgs_index: [],
        units_msgs: [],
        unitsMsgsGroupBy: [],
        unitsMsgsGroupByindex: [],
    },
    actions: {
        fetchUnitsMsgs4Date({dispatch ,commit, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                let isToday = (args.date == getters.getDateToday)
                if (!getters.apiToken || !args.unitId || !args.date) {
                    return reject(false)
                }

                // if today come throw 00h - it doesn't work (index ???)
                // let unitsMsg = getters.getUnitsMsgs.find(t => (t.unit_id == args.unitId && t.date == args.date))
                // let record = getters.getUnitsMsgsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
                // if(unitsMsg && args.date !== getters.getDateToday) {
                //     return resolve(true)
                // }
                dispatch('setLastCall', {name: 'fetchUnitsMsgs4Date', time: Date.now() / 1000})
                let params = {withAddress: 1, ...args.params}
                this.$api.unitsmsgs.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeUnitsMsgs', response.data)
                            commit('setUnitsMsgsIndex', {
                                ...args,
                                count: response.data.length,
                                timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.units_msgs) : false
                            })
                            if (!args.unitId && response.data.length) {
                                // debugger
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsMsgs4Date', inprogress: false})
                    });
            })
        },
        fetchUnitsMsgs4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }
                let unitsMsg = getters.getUnitsMsgs.find(t => (t.unit_id == args.unitId && t.time_start?.utc == args.from && t.time_end?.utc == args.to))
                if (unitsMsg) {
                    return resolve(true)
                }
                dispatch('setLastCall', {name: 'fetchUnitsMsgs4Period', time: Date.now() / 1000})
                let params = {withAddress: 1, ...args.params}
                this.$api.unitsmsgs.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeUnitsMsgs', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsMsgs4Period', time: Date.now() / 1000})
                    });
            })
        },
        fetchUnitsMsgsGroupBy4Date({dispatch ,commit, getters}, args) {
            return new Promise((resolve, reject) => {
                let lmsg = getters.unitsLmsgs.find(m => m.object_id == args.unitId)
                let sensors = Object.keys(lmsg?.sensors || {})
                if(!getters.unitsLmsgsFullLoadByIds[args.unitId] || !sensors?.includes(args.groupBy)) {
                    console.warn("No sensors: Unit:" + args.unitId + ' Sensor GroupBy:' + args.groupBy)
                    resolve(true)
                }

                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                let isToday = (args.date == getters.getDateToday)
                if (!getters.apiToken || !args.unitId || !args.date) {
                    return reject(false)
                }

                // if today come throw 00h - it doesn't work (index ???)
                // let unitsMsg = getters.getUnitsMsgs.find(t => (t.unit_id == args.unitId && t.date == args.date))
                // let record = getters.getUnitsMsgsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
                // if(unitsMsg && args.date !== getters.getDateToday) {
                //     return resolve(true)
                // }
                dispatch('setLastCall', {name: 'fetchUnitsMsgsGroupBy4Date', time: Date.now() / 1000})
                let params = {withAddress: 1, ...args.params}
                this.$api.unitsmsgs.getGroupBy4Date(args.unitId, args.date, args.groupBy, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeUnitsMsgsGroupBy', response.data)
                            commit('setUnitsMsgsGroupByIndex', {
                                ...args,
                                count: response.data.length,
                                timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.units_msgs) : false
                            })
                            if (!args.unitId && response.data.length) {
                                // debugger
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsMsgsGroupBy4Date', inprogress: false})
                    });
            })
        },
        fetchUnitsMsgsGroupBy4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }
                let unitsMsg = getters.getUnitsMsgs.find(t => (t.unit_id == args.unitId && t.time_start?.utc == args.from && t.time_end?.utc == args.to))
                if (unitsMsg) {
                    return resolve(true)
                }
                dispatch('setLastCall', {name: 'fetchUnitsMsgsGroupBy4Period', time: Date.now() / 1000})
                let params = {withAddress: 1, ...args.params}
                this.$api.unitsmsgs.get4Period(args.unitId, args.from, args.to, args.groupBy, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeUnitsMsgsGroupBy', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsMsgsGroupBy4Period', time: Date.now() / 1000})
                    });
            })
        },
        reloadUnitsMsgs({commit, dispatch}, args) {
            commit('clearUnitsMsgs')
            return dispatch('fetchUnitsMsgs4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        removeUnitsMsgsIndex(state) {
            state.units_msgs_index =
                state.units_msgs_index.filter(i => !i.timeout || i.timeout > (Date.now() / 1000))
        },
        setUnitsMsgsIndex(state, params) {
            let i = state.units_msgs_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.units_msgs_index.push(Object.freeze({...params}))
            } else {
                state.units_msgs_index[i] = Object.freeze({...state.units_msgs_index[i], ...params})
            }
        },
        storeUnitsMsgs(state, nUnitsMsgs) {
            nUnitsMsgs = nUnitsMsgs
                .filter(t => !state.units_msgs.find(st => {
                    return st.unit_id == t.unit_id
                        && st.type == t.type
                        && st.time_start?.utc == t.time_start?.utc
                        // && st.time_end?.utc == t.time_end?.utc
                }))
                .map(t => Object.freeze(t))

            // if(nUnitsMsgs.length) state.units_msgs.push(...nUnitsMsgs)
            if (!nUnitsMsgs.length) return false
            if (!state.units_msgs.length) {
                state.units_msgs = nUnitsMsgs
                return true
            }
            state.units_msgs.push(...nUnitsMsgs)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nUnitsMsgs)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.units_msgs.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        setUnitsMsgsGroupByIndex(state, params) {
            let i = state.unitsMsgsGroupByindex.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.unitsMsgsGroupByindex.push(Object.freeze({...params}))
            } else {
                state.unitsMsgsGroupByindex[i] = Object.freeze({...state.unitsMsgsGroupByindex[i], ...params})
            }
        },
        storeUnitsMsgsGroupBy(state, nUnitsMsgs) {
            nUnitsMsgs = nUnitsMsgs
                .filter(t => !state.unitsMsgsGroupBy.find(st => {
                    return st.unit_id == t.unit_id
                        && st.type == t.type
                        && st.time_start?.utc == t.time_start?.utc
                        && st.time_end?.utc == t.time_end?.utc
                }))
                .map(t => Object.freeze(t))

            // if(nUnitsMsgs.length) state.unitsMsgsGroupBy.push(...nUnitsMsgs)
            if (!nUnitsMsgs.length) return false
            if (!state.unitsMsgsGroupBy.length) {
                state.unitsMsgsGroupBy = nUnitsMsgs
                return true
            }

            nUnitsMsgs.forEach(nG => {
                let index = state.unitsMsgsGroupBy.findIndex(g => g.xid == nG.xid)
                if(index !== -1) {
                    state.unitsMsgsGroupBy[index] = nG
                } else {
                    state.unitsMsgsGroupBy.push(nG)
                }
            })

            //vue recommended
            // const chunks = arraySplitIntoChunks(nUnitsMsgs)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.unitsMsgsGroupBy.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearUnitsMsgs(state) {
            state.units_msgs = [];
            state.units_msgs_index = [];
            state.unitsMsgsGroupBy = [];
            state.unitsMsgsGroupByindex = [];
        },
    },
    getters: {
        getUnitsMsgsIndex(state) {
            return state.units_msgs_index
        },
        getUnitsMsgs(state) {
            return state.units_msgs
        },
        getUnitsMsgsGroupByIndex(state) {
            return state.unitsMsgsGroupByindex
        },
        getUnitsMsgsGroupBy(state) {
            return state.unitsMsgsGroupBy
        },
        getUnitsMsgsByIds(state) {
            return state.units_msgs.reduce((tripsByIds, unitsMsg) => {
                tripsByIds[unitsMsg.id] = unitsMsg
                return tripsByIds
            }, {})
        },
    }
}
